import React from "react";
import Diagram, { LineMarkerType } from "../../../shared/components/Diagram";
import classNames from "./HorizonControlDesigner.module.scss";

export interface IHorizonControlDesignerProps {
  control: any;
}

export const HorizonControlDesigner = (props: IHorizonControlDesignerProps) => {
  const { control } = props;

  if (!control?.horizonControlSettings) {
    return null;
  }

  let inputNodes = control.horizonControlSettings.entities?.map((entity, index) => ({
    id: entity,
    x: index * 160,
    style: { backgroundColor: "royalblue" },
  }));
  let nodeCount = inputNodes?.length || 0;

  inputNodes = inputNodes.concat(
    control.horizonControlSettings.dimensions?.map((dimension, index) => ({
      id: dimension,
      x: (nodeCount + index) * 160,
      style: { backgroundColor: "blueviolet" },
    }))
  );

  let actionNodes = control.horizonControlSettings.actions?.map((action, index) => ({
    id: "action" + index,
    name: action.action,
    x: index * 180,
    y: 0,
    style: { backgroundColor: "teal" },
  }));

  let actionLinks = [];

  for (var i = actionNodes.length - 1; i > 0; i--) {
    actionLinks.push({ fromNodeId: "action" + (i - 1), toNodeId: "action" + i });
  }

  return (
    <>
      <div className={classNames.section}>
        <div className={classNames.sectionLabel}>Inputs</div>
        <Diagram diagram={{ nodes: inputNodes, height: 40 }} />
      </div>
      <div className={classNames.section}>
        <div className={classNames.sectionLabel}>Actions</div>
        <Diagram
          diagram={{
            nodes: actionNodes,
            links: actionLinks,
            height: 40,
            defaultLinkProps: { lineMarkerType: LineMarkerType.arrowEnd },
          }}
        />
      </div>
    </>
  );
};

export default HorizonControlDesigner;
